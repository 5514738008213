<template>
  <b-card text-variant="opalean-gray-dark" class="card-custom gutter-b w-100 h-100">
    <b-card-text class="flex-column align-items-center h-100">
      <!-- begin:: Table of operations -->
      <div class="table-container" style="overflow: auto; height: 42vh">
        <table class="table-custom table-striped table-hover">
          <thead>
            <tr>
              <!-- Placeholder for row number column -->
              <th scope="col" style="width: 1%; padding: 8px; color: transparent">0</th>
              <!-- Dynamic table headers -->
              <th v-for="(header, index) in headers" :key="index" :style="getHeaderClass(index)" style="padding: 8px" :id="'header-' + index">
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="!noDataFound">
              <!-- Display rows if there are data -->
              <tr v-for="(row, rowIndex) in rows" :key="rowIndex" :style="getRowClass(rowIndex, -1)" ref="bodyRows">
                <th scope="row" style="padding: 8px; text-align: center">{{ rowIndex + 1 }}</th>
                <td
                  v-for="(cell, columnIndex) in row"
                  :key="columnIndex"
                  :style="getCellClass(rowIndex, columnIndex)"
                  :contenteditable="getCellEditable(rowIndex, columnIndex)"
                  style="padding: 8px; text-align: center"
                  @input="updateCell($event, rowIndex, columnIndex)"
                >
                  {{ cell }}
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="100%" class="text-center" style="color: dimgrey; font-style: italic; background-color: white; height: 38vh" v-translate>
                  No data found
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-card-text>

    <!-- begin:: Card footer -->
    <template v-slot:footer>
      <div class="d-flex align-items-stretch w-100">
        <!-- begin:: List of errors and warnings -->
        <div class="flex-grow-1 d-flex flex-column">
          <div class="d-flex flex-row align-items-start">
            <!-- begin:: Display icons and number of errors -->
            <button class="d-flex align-items-center justify-content-center error-button" :class="{ active: isActiveError }" @click="toggleError">
              <b-icon-exclamation-circle-fill class="text-danger h3 m-2"></b-icon-exclamation-circle-fill>
              <span class="font-size-h6 text-center mr-2"> {{ numberOfErrors }} <translate>Errors</translate></span>
            </button>
            <!-- end:: Display icons and number of errors -->

            <!-- begin:: Display separator -->
            <div class="separator-custom mx-4"></div>
            <!-- end:: Display separator -->

            <!-- begin:: Display icons and number of warnings -->
            <button class="d-flex align-items-center justify-content-center warning-button" :class="{ active: isActiveWarning }" @click="toggleWarning">
              <b-icon-exclamation-triangle-fill class="text-warning h3 m-2"></b-icon-exclamation-triangle-fill>
              <span class="font-size-h6 text-center mr-2">{{ numberOfWarnings }} <translate>Warnings</translate></span>
            </button>
            <!-- end:: Display icons and number of warnings -->

            <div class="ml-auto d-flex flex-row align-items-center">
              <!-- begin:: Display cancel/return button -->
              <button
                v-if="showCancelOrReturnButton"
                class="d-flex align-items-center justify-content-center action-button"
                :class="cancelButtonProperties.class"
                @click="cancelUpload"
                style="width: 125px"
              >
                <b-icon :icon="cancelButtonProperties.icon" class="h3 m-2" :style="cancelButtonProperties.style"></b-icon>
                <span class="font-size-h6 text-center mr-2" v-translate>{{ cancelButtonProperties.text }}</span>
              </button>
              <!-- end:: Display cancel/return button -->

              <div class="ml-4"></div>

              <!-- begin:: Display refresh/import button -->
              <button
                v-if="showRefreshButton"
                class="d-flex align-items-center justify-content-center action-button"
                @click="isCorrectFile ? uploadData() : refreshData()"
                :class="refreshButtonProperties.class"
                style="width: 125px"
              >
                <b-icon :icon="refreshButtonProperties.icon" class="h3 m-2" :style="refreshButtonProperties.style"></b-icon>
                <span class="font-size-h6 text-center mr-2" v-translate>{{ refreshButtonProperties.text }}</span>
              </button>
              <!-- end:: Display refresh/import button -->
            </div>
          </div>

          <div class="mb-4"></div>

          <!-- begin:: Table of errors and warnings -->
          <div class="table-container" style="overflow: auto; height: 14vh">
            <table class="table-custom table-striped table-hover">
              <thead>
                <tr>
                  <!-- Error status icon column -->
                  <th scope="col" style="width: 1%; padding: 8px; text-align: center">
                    <b-icon-info-circle-fill class="text-muted"></b-icon-info-circle-fill>
                  </th>
                  <!-- Description column -->
                  <th scope="col" style="padding: 8px"><translate>Description</translate></th>
                  <!-- Line number column -->
                  <th scope="col" style="width: 10%; padding: 8px"><translate>Line</translate></th>
                  <!-- Column name column -->
                  <th scope="col" style="width: 10%; padding: 8px"><translate>Column</translate></th>
                </tr>
              </thead>
              <tbody>
                <template v-if="numberOfErrors > 0 || numberOfWarnings > 0">
                  <!-- Table of errors and warnings -->
                  <tr v-for="(error, index) in filteredData" :key="index">
                    <!-- Display error/warning icon based on status -->
                    <td style="padding: 8px; text-align: center">
                      <b-icon-exclamation-circle-fill
                        v-if="error.status === 'error' || error.status === 'blockingerror'"
                        class="text-danger"
                      ></b-icon-exclamation-circle-fill>
                      <b-icon-exclamation-triangle-fill v-else class="text-warning"></b-icon-exclamation-triangle-fill>
                    </td>
                    <!-- Description column -->
                    <td style="padding: 8px">{{ error.description }}</td>
                    <!-- Line number column -->
                    <td style="padding: 8px">{{ error.rowIndex + 1 }}</td>
                    <!-- Column name column -->
                    <td style="padding: 8px">{{ headers[error.columnIndex] }}</td>
                  </tr>
                </template>
                <template v-else>
                  <!-- No errors or warnings found message -->
                  <tr>
                    <td colspan="100%" class="text-center" style="color: dimgrey; font-style: italic; background-color: white; height: 10vh" v-translate>
                      No errors or warnings found
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <!-- end:: Table of errors and warnings -->
        </div>
      </div>
    </template>
    <!-- end:: Card footer -->
  </b-card>
</template>

<script>
import Vue from "vue";
let vm = new Vue();

import { mapGetters } from "vuex";

import ApiService from "@/core/services/api.service";

const AllowedColumnsInFile = Object.freeze({
  DATE: "Date",
  TYPE: "Typ",
  REF_OP: "RefOp",
  CMR: "CMR",
  REF_SHIP: "RefShip",
  SHIP: "Ship",
  REF_TPS: "RefTps",
  TPS: "Tps",
  REF_REC: "RefRec",
  REC: "Rec",
});

// Constants defining user roles
const Roles = Object.freeze({
  4: "MC",
  6: "MT",
  8: "MD",
});

// Constants defining different types
const Type = Object.freeze({
  C: "C",
  T: "T",
  D: "D",
});

const ProcessStatus = Object.freeze({
  ERROR: "error",
  BLOCKING_ERROR: "blockingerror",
  WARNING: "warning",
  OK: "ok",
});

export default {
  data() {
    return {
      ProcessStatus,
      AllowedColumnsInFile,
      Roles,
      Type,
      isCorrectFile: false,
      numberOfErrors: -1,
      isActiveError: false,
      numberOfWarnings: 0,
      isActiveWarning: false,
      headers: [],
      rows: [],
      validationErrors: [],
      dataProcessing: [],
      requiredColumns: [],
      operationType: "",
      isBlockingError: false,
      apiReturn: false,
      showCancelButton: false,
      showReturnButton: false,
    };
  },
  props: {
    selectedFile: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getPartners", "getPallets", "getUser"]),
    /**
     * Computed property to filter data based on error and warning statuses.
     * @type {Array}
     * @returns {Array} - Filtered data based on error and warning statuses.
     */
    filteredData() {
      if (this.isActiveError && this.isActiveWarning) {
        return this.dataProcessing.filter(
          (error) => error.status === ProcessStatus.ERROR || error.status === ProcessStatus.BLOCKING_ERROR || error.status === ProcessStatus.WARNING
        );
      } else if (this.isActiveError) {
        return this.dataProcessing.filter((error) => error.status === ProcessStatus.ERROR || error.status === ProcessStatus.BLOCKING_ERROR);
      } else if (this.isActiveWarning) {
        return this.dataProcessing.filter((error) => error.status === ProcessStatus.WARNING);
      } else {
        return [];
      }
    },

    /**
     * Computed property to check if no data is found in rows.
     * @type {boolean}
     * @returns {boolean} - True if no data is found, false otherwise.
     */
    noDataFound() {
      return this.rows.length === 0;
    },

    /**
     * Determines whether to show the cancel or return button.
     * @return {boolean} - True if either cancel or return button should be shown.
     */
    showCancelOrReturnButton() {
      return this.showCancelButton || this.showReturnButton;
    },

    /**
     * Provides the properties for the cancel or return button.
     * @return {Object} - Object containing class, icon, style, and text for the button.
     */
    cancelButtonProperties() {
      return {
        class: this.showCancelButton ? "action-button-cancel" : "action-button-return",
        icon: this.showCancelButton ? "x-circle-fill" : "arrow-left-circle-fill",
        style: this.showCancelButton ? "color: #dc3545" : "color: #6c757d",
        text: this.showCancelButton ? "Cancel" : "Return",
      };
    },

    /**
     * Determines whether to show the refresh or import button.
     * @return {boolean} - True if either refresh or import button should be shown.
     */
    showRefreshButton() {
      return this.shouldDisplayButton("refresh") || this.shouldDisplayButton("import");
    },

    /**
     * Provides the properties for the refresh or import button.
     * @return {Object} - Object containing class, icon, style, and text for the button.
     */
    refreshButtonProperties() {
      return {
        class: this.isCorrectFile ? "action-button-upload" : "action-button-refresh",
        icon: this.isCorrectFile ? "cloud-upload" : "arrow-clockwise",
        style: this.isCorrectFile ? "color: #28a745" : "color: #007bff",
        text: this.isCorrectFile ? "Upload" : "Refresh",
      };
    },
  },
  methods: {
    /* Error and Warning methods */
    /**
     * Toggles the visibility of error messages and adjusts column widths.
     * @function
     */
    toggleError() {
      this.isActiveError = !this.isActiveError;
    },

    /**
     * Toggles the visibility of warning messages and adjusts column widths.
     * @function
     */
    toggleWarning() {
      this.isActiveWarning = !this.isActiveWarning;
    },

    /* Button methods */
    /**
     * Determines if a button should be displayed based on its type.
     * @param {string} buttonType - The type of the button ('refresh' or 'import').
     * @return {boolean} - True if the button should be displayed.
     */
    shouldDisplayButton(buttonType) {
      switch (buttonType) {
        case "refresh":
          return !this.noDataFound && !this.isBlockingError && !this.isCorrectFile;
        case "import":
          return !this.noDataFound && !this.isBlockingError && this.isCorrectFile;
        default:
          return false;
      }
    },

    /**
     * Resets all states and data related to the file upload.
     * @function
     */
    cancelUpload() {
      this.isCorrectFile = false;
      this.numberOfErrors = 0;
      this.isActiveError = false;
      this.numberOfWarnings = 0;
      this.isActiveWarning = false;
      this.headers = [];
      this.rows = [];
      this.validationErrors = [];
      this.dataProcessing = [];
      this.requiredColumns = [];
      this.operationType = "";
      this.$emit("fileDropped", false);
    },

    /**
     * Refreshes the data by trimming cell content and synchronizing column widths.
     * @function
     */
    refreshData() {
      this.$refs.bodyRows.forEach((row) => {
        Array.from(row.children).forEach((cell) => {
          cell.innerText = cell.innerText.trim();
        });
      });

      this.processRowsAndColumns(this.rows, this.headers);
    },

    /**
     * Parses CSV content into headers and rows.
     * @param {string} file - The CSV content as a string.
     * @function
     */
    parseCSVToTable(file) {
      const lines = file.split("\n");
      const headers = lines[0].split(",");
      const rows = lines.slice(1).map((line) => line.split(","));

      const dateIndex = headers.indexOf("Date");
      if (dateIndex !== -1) {
        rows.forEach((row) => {
          const date = row[dateIndex];
          if (date) {
            row[dateIndex] = this.formatDate(date, "DD/MM/YYYY");
          }
        });
      }

      const filteredRows = rows.filter((row) => row.some((cell) => cell.trim()));

      this.processRowsAndColumns(filteredRows, headers);

      this.headers = headers;
      this.rows = filteredRows;
    },

    /* Process Header and Cell methods */
    /**
     * Gets the status of a header column.
     * @param {number} columnIndex - The index of the column.
     * @returns {Object|undefined} - The status of the header or undefined if not found.
     * @function
     */
    getHeader(columnIndex) {
      return this.dataProcessing.find((result) => result.rowIndex === -1 && result.columnIndex === columnIndex);
    },

    /**
     * Gets the CSS class for a header column based on its status.
     * @param {number} columnIndex - The index of the column.
     * @returns {string} - The CSS class for the header.
     * @function
     */
    getHeaderClass(columnIndex) {
      const header = this.getHeader(columnIndex);
      if (!header) return "";

      switch (header.status) {
        case ProcessStatus.ERROR:
        case ProcessStatus.BLOCKING_ERROR:
          return "background-color: #f8d7da; border: 2px solid #e74a3b;";
        case ProcessStatus.WARNING:
          return "background-color: #ffe8cc; border: 2px solid #ffa500;";
        default:
          return;
      }
    },

    /**
     * Gets the status of a cell based on its position.
     * @param {number} rowIndex - The index of the row.
     * @param {number} columnIndex - The index of the column.
     * @returns {Object|undefined} - The status of the cell or undefined if not found.
     * @function
     */
    getCell(rowIndex, columnIndex) {
      return this.dataProcessing.find((result) => result.rowIndex === rowIndex && result.columnIndex === columnIndex);
    },

    /**
     * Gets the CSS class for a row based on cell status.
     * @param {number} rowIndex - The index of the row.
     * @param {number} columnIndex - The index of the column.
     * @returns {string} - The CSS class for the row.
     * @function
     */
    getRowClass(rowIndex, columnIndex) {
      const cellStatus = this.getCell(rowIndex, columnIndex);
      if (!cellStatus) return "";
      if (cellStatus.status === ProcessStatus.ERROR) return "background-color: #ffcccc;";
    },

    /**
     * Gets the CSS class for a cell based on its status.
     * @param {number} rowIndex - The index of the row.
     * @param {number} columnIndex - The index of the column.
     * @returns {string} - The CSS class for the cell.
     * @function
     */
    getCellClass(rowIndex, columnIndex) {
      const currentCell = this.getCell(rowIndex, columnIndex);
      if (!currentCell) return "";

      if (currentCell.status === ProcessStatus.ERROR) {
        return "background-color: #f8d7da; border: 2px solid #e74a3b;";
      }

      if (currentCell.status === ProcessStatus.WARNING) {
        return "background-color: #ffe8cc; border: 2px solid #ffa500;";
      }
    },

    /**
     * Determines if a cell is editable based on its status.
     * @param {number} rowIndex - The index of the row.
     * @param {number} columnIndex - The index of the column.
     * @returns {boolean} - True if the cell is editable, false otherwise.
     * @function
     */
    getCellEditable(rowIndex, columnIndex) {
      const cellStatus = this.getCell(rowIndex, columnIndex);
      if (cellStatus) {
        return cellStatus.status !== ProcessStatus.OK;
      }
      return false;
    },

    /**
     * Updates the value of a cell based on user input.
     * @param {Event} event - The input event containing the new value.
     * @param {number} rowIndex - The index of the row.
     * @param {number} columnIndex - The index of the column.
     * @function
     */
    updateCell(event, rowIndex, columnIndex) {
      const cellValue = event.target.innerText.trim();
      this.rows[rowIndex][columnIndex] = cellValue;
    },

    /* Process Rows and Columns methods */
    /**
     * Processes rows and headers for validation and error handling.
     * @param {Array} rows - Array of row data.
     * @param {Array} headers - Array of column headers.
     * @function
     */
    processRowsAndColumns(rows, headers) {
      this.resetProcessingData();

      const headerErrors = this.processHeader(headers);
      if (headerErrors) {
        this.handleHeaderErrors(headerErrors);
      }

      this.processCells(rows, headers);

      this.showCancelButton = !this.isBlockingError;
      console.log("showCancelButton", this.showCancelButton);
      this.showReturnButton = this.isBlockingError;
      console.log("showReturnButton", this.showReturnButton);
    },

    /**
     * Resets the processing data, clearing errors and warnings.
     * @function
     */
    resetProcessingData() {
      this.dataProcessing = [];
      this.numberOfErrors = 0;
      this.numberOfWarnings = 0;
    },

    /**
     * Processes the header to validate columns and handle errors.
     * @param {Array} headers - Array of column headers.
     * @returns {Object|null} - Object containing header errors or null if no errors.
     * @function
     */
    processHeader(headers) {
      const headerErrors = this.getColumnsErrors(headers);

      headers.forEach((header, index) => {
        if (this.isPalletReference(header) && !this.isValidPalletReference(header)) {
          this.addProcessingError(
            -1,
            index,
            ProcessStatus.BLOCKING_ERROR,
            `${vm.$gettext("The pallet reference")} "${header.substring(1)}" ${vm.$gettext("does not exist")}.`
          );
          this.isBlockingError = true;
        }
      });

      return headerErrors;
    },

    /**
     * Handles header errors by adding them to the processing data.
     * @param {Object} errors - Object containing header errors.
     * @function
     */
    handleHeaderErrors(errors) {
      Object.entries(errors).forEach(([index, error]) => {
        this.addProcessingError(-1, parseInt(index), error.status, error.description);
      });
    },

    /**
     * Validates column headers and returns errors if any invalid columns are found.
     * @param {Array} headers - Array of column headers.
     * @returns {Object|null} - Object containing column errors or null if no errors.
     * @function
     */
    getColumnsErrors(headers) {
      if (headers == null || headers.length < 10) return false;

      const errors = {};
      headers.forEach((column, index) => {
        if (!this.isValidColumn(column)) {
          errors[index] = this.generateColumnError(column);
        }
      });

      return Object.keys(errors).length ? errors : null;
    },

    /**
     * Checks if a column header is valid based on predefined allowed columns and pallet references.
     * @param {string} column - The column header to validate.
     * @param {Set} palletRefs - Set of valid pallet references.
     * @returns {boolean} - True if the column is valid, otherwise false.
     * @function
     */
    isValidColumn(column) {
      const validColumnsValues = Object.values(AllowedColumnsInFile);
      return validColumnsValues.includes(column) || this.isPalletReference(column);
    },

    /**
     * Generates an error message for an invalid column.
     * @param {string} column - The invalid column header.
     * @returns {Object} - Error object with status and description.
     * @function
     */
    generateColumnError(column) {
      const validColumnsList = Object.values(AllowedColumnsInFile).join(", ");

      return {
        status: ProcessStatus.ERROR,
        description: `${vm.$gettext("The column")} ${column} ${vm.$gettext("is not valid. Please use one of the following columns")}: ${validColumnsList}`,
      };
    },

    /**
     * Checks if a column header is a pallet reference (starts with "+" or "-").
     * @param {string} column - The column header to check.
     * @returns {boolean} - True if it is a pallet reference, otherwise false.
     * @function
     */
    isPalletReference(column) {
      return column.startsWith("+") || column.startsWith("-");
    },

    /**
     * Checks if a pallet reference is valid by comparing it with known pallet references.
     * @param {string} column - The pallet reference to check.
     * @returns {boolean} - True if the pallet reference is valid, otherwise false.
     * @function
     */
    isValidPalletReference(column) {
      const palletRef = column.substring(1);
      return this.getPallets.some((p) => p.PalletRef === palletRef);
    },

    /**
     * Processes cells in rows based on column headers and evaluates their validity.
     * @param {Array} rows - Array of row data.
     * @param {Array} headers - Array of column headers.
     * @function
     */
    processCells(rows, headers) {
      const indices = this.getColumnIndices(headers);

      rows.forEach((row, rowIndex) => {
        let isMyPartnerRefPresent = false;

        headers.forEach((columnName, columnIndex) => {
          const cellValue = row[columnIndex].trim();
          const { status, description } = this.evaluateCell(columnName, cellValue, indices);
          this.addProcessingError(rowIndex, columnIndex, status, description);

          const myPartnerRef = this.getPartners.find((p) => p.PartnerID === this.getUser.ClientID)?.Reference;
          if (myPartnerRef && cellValue === myPartnerRef) {
            isMyPartnerRefPresent = true;
          }
        });

        if (!isMyPartnerRefPresent) {
          this.addProcessingError(rowIndex, -1, ProcessStatus.ERROR, `${vm.$gettext("Your partner reference is not present in the line")}.`);
        }
      });
    },

    /**
     * Maps allowed columns to their indices in the headers array.
     * @param {Array} headers - Array of column headers.
     * @returns {Object} - Object mapping column names to their indices.
     * @function
     */
    getColumnIndices(headers) {
      const indices = {};
      Object.keys(AllowedColumnsInFile).forEach((key) => {
        const column = AllowedColumnsInFile[key];
        indices[key] = headers.indexOf(column);
      });
      return indices;
    },

    /**
     * Evaluates a cell value based on its column header and returns its status and description.
     * @param {string} columnName - The column header.
     * @param {string} cellValue - The value of the cell.
     * @param {Object} indices - Object mapping column names to their indices.
     * @returns {Object} - Object containing status and description.
     * @function
     */
    evaluateCell(columnName, cellValue, indices) {
      if (columnName === AllowedColumnsInFile.TYPE) {
        this.setOperationType(cellValue, indices);
      }

      switch (columnName) {
        case AllowedColumnsInFile.DATE:
          return this.evaluateDateCell(cellValue);
        case AllowedColumnsInFile.TYPE:
          return this.evaluateTypeCell(cellValue);
        case AllowedColumnsInFile.REF_SHIP:
        case AllowedColumnsInFile.REF_TPS:
        case AllowedColumnsInFile.REF_REC:
          return this.evaluatePartnerCell(columnName, cellValue, indices);
        default:
          return this.evaluatePalletCell(columnName, cellValue);
      }
    },

    /**
     * Sets the operation type and updates required columns based on it.
     * @param {string} cellValue - The value of the cell indicating the operation type.
     * @param {Object} indices - Object mapping column names to their indices.
     * @function
     */
    setOperationType(cellValue, indices) {
      if (cellValue) {
        this.requiredColumns = this.getRequiredColumns(cellValue, indices);
        this.operationType = cellValue;
      } else {
        this.requiredColumns = [];
        this.operationType = "";
      }
    },

    /**
     * Evaluates the validity of a date cell.
     * @param {string} cellValue - The value of the date cell.
     * @returns {Object} - Object containing status and description.
     * @function
     */
    evaluateDateCell(cellValue) {
      const isValidDate = this.isValidDateFormat(cellValue);
      if (!isValidDate) {
        return { status: ProcessStatus.ERROR, description: `${vm.$gettext("Invalid date format. Please use DD/MM/YYYY")}.` };
      }

      const isDateInFuture = this.isDateInFuture(cellValue);
      if (isDateInFuture) {
        return { status: ProcessStatus.ERROR, description: `${vm.$gettext("Date cannot be in the future")}.` };
      }

      return { status: ProcessStatus.OK, description: "" };
    },

    /**
     * Checks if the date format is valid (DD/MM/YYYY).
     * @param {string} date - The date string to validate.
     * @returns {boolean} - True if the date format is valid, otherwise false.
     * @function
     */
    isValidDateFormat(date) {
      const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
      return datePattern.test(date);
    },

    /**
     * Checks if the date is in the future compared to the current date.
     * @param {string} date - The date string to check.
     * @returns {boolean} - True if the date is in the future, otherwise false.
     * @function
     */
    isDateInFuture(date) {
      const currentDate = new Date();
      const [day, month, year] = date.split("/");
      const cellDate = new Date(`${year}-${month}-${day}`);
      return cellDate > currentDate;
    },

    /**
     * Evaluates the operation type cell to ensure it is not empty.
     * @returns {Object} - Object containing status and description.
     * @function
     */
    evaluateTypeCell() {
      if (this.operationType === "") {
        return { status: ProcessStatus.ERROR, description: `${vm.$gettext("The operation type is required")}.` };
      }
      return { status: ProcessStatus.OK, description: "" };
    },

    /**
     * Evaluates partner reference cells for validity and required conditions.
     * @param {string} columnName - The column name of the partner reference.
     * @param {string} cellValue - The value of the cell.
     * @param {Object} indices - Object mapping column names to their indices.
     * @returns {Object} - Object containing status and description.
     * @function
     */
    evaluatePartnerCell(columnName, cellValue, indices) {
      if (cellValue && !this.getPartners.some((partner) => partner.Reference === cellValue)) {
        return { status: ProcessStatus.ERROR, description: `${vm.$gettext("The partner")} ${cellValue} ${vm.$gettext("does not exist")}.` };
      }

      const keyColumnName = Object.keys(AllowedColumnsInFile).find((key) => AllowedColumnsInFile[key] === columnName);
      if (this.requiredColumns.includes(indices[keyColumnName]) && !cellValue && this.operationType !== "") {
        return {
          status: ProcessStatus.ERROR,
          description: `${vm.$gettext("The reference of the partner is required for the operation type")} ${this.operationType}.`,
        };
      }

      return { status: ProcessStatus.OK, description: "" };
    },

    /**
     * Evaluates pallet cells for validity and ensures conditions are met.
     * @param {string} columnName - The column name of the pallet reference.
     * @param {string} cellValue - The value of the cell.
     * @returns {Object} - Object containing status and description.
     * @function
     */
    evaluatePalletCell(columnName, cellValue) {
      if (this.isPalletReference(columnName)) {
        if (!cellValue) {
          return { status: ProcessStatus.WARNING, description: `${vm.$gettext("The value is missing. The default value is 0")}.` };
        }

        if (columnName.startsWith("-") && this.operationType === "T" && cellValue > 0) {
          return {
            status: ProcessStatus.ERROR,
            description: `${vm.$gettext("For transfer operations (T), pallets cannot be received. The value must be 0")}.`,
          };
        }
      }

      return { status: ProcessStatus.OK, description: "" };
    },

    /**
     * Determines required columns based on the operation type.
     * @param {string} operationType - The type of operation (e.g., "C", "T", "D").
     * @param {Object} indices - Object mapping column names to their indices.
     * @returns {Array} - Array of required column indices.
     * @function
     */
    getRequiredColumns(operationType, indices) {
      switch (operationType) {
        case Type.C:
          return [indices.REF_SHIP, indices.REF_SHIP + 1, indices.REF_TPS, indices.REF_TPS + 1];
        case Type.T:
          return [indices.REF_SHIP, indices.REF_SHIP + 1, indices.REF_REC, indices.REF_REC + 1];
        case Type.D:
          return [indices.REF_TPS, indices.REF_TPS + 1, indices.REF_REC, indices.REF_REC + 1];
        default:
          return [];
      }
    },

    /**
     * Adds an error or warning to the processing data and checks the error threshold.
     * @param {number} rowIndex - Index of the row where the error occurred.
     * @param {number} columnIndex - Index of the column where the error occurred.
     * @param {string} status - Status of the error (e.g., Status.ERROR, Status.WARNING).
     * @param {string} description - Description of the error or warning.
     * @function
     */
    addProcessingError(rowIndex, columnIndex, status, description) {
      if (status === ProcessStatus.ERROR || status === ProcessStatus.BLOCKING_ERROR) {
        this.numberOfErrors++;
      } else if (status === ProcessStatus.WARNING) {
        this.numberOfWarnings++;
      }

      if (!this.checkErrorThreshold()) {
        if (columnIndex === -1) {
          let insertIndex = this.dataProcessing.findIndex((error) => error.rowIndex === rowIndex && error.columnIndex !== -1);

          if (insertIndex === -1) {
            this.dataProcessing.push({ rowIndex, columnIndex, status, description });
          } else {
            this.dataProcessing.splice(insertIndex, 0, { rowIndex, columnIndex, status, description });
          }
        } else {
          this.dataProcessing.push({ rowIndex, columnIndex, status, description });
        }
      }
    },

    /**
     * Checks if the number of errors exceeds the allowed threshold.
     * @returns {boolean} - True if the error threshold is exceeded, otherwise false.
     * @function
     */
    checkErrorThreshold() {
      const errorThreshold = 100;
      if (this.numberOfErrors > errorThreshold) {
        this.finishProcess(
          ProcessStatus.ERROR,
          `${vm.$gettext("The number of errors exceeded the threshold of")} ${errorThreshold} ${vm.$gettext("errors. The file processing has been canceled")}.`
        );
      }
      return false;
    },
    /* Date format methods */

    /**
     * Formats a date string or Date object into a specified output format.
     * @param {string | Date} date - Date string in format DD/MM/YYYY or Date object.
     * @param {string} outputFormat - Desired output format (default is "YYYY-MM-DDTHH:mm:ssZ").
     * @returns {string} - Formatted date string.
     */
    formatDate(date, outputFormat = "YYYY-MM-DDTHH:mm:ssZ") {
      const datePattern = /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/;
      const now = new Date();
      let day, month, year;

      if (typeof date === "string" && datePattern.test(date)) {
        const [, d, m, y] = date.match(datePattern);
        day = d.padStart(2, "0");
        month = m.padStart(2, "0");
        year = y.length === 2 ? `20${y}` : y;
      } else if (date instanceof Date) {
        day = String(date.getDate()).padStart(2, "0");
        month = String(date.getMonth() + 1).padStart(2, "0");
        year = String(date.getFullYear());
      } else {
        return date; // Return original date if it's neither string nor Date object.
      }

      const replacements = {
        DD: day,
        MM: month,
        YYYY: year,
        HH: String(now.getHours()).padStart(2, "0"),
        mm: String(now.getMinutes()).padStart(2, "0"),
        ss: String(now.getSeconds()).padStart(2, "0"),
        Z: `+${String(-now.getTimezoneOffset() / 60).padStart(2, "0")}:00`,
      };

      return outputFormat.replace(/DD|MM|YYYY|HH|mm|ss|Z/g, (match) => replacements[match] || match);
    },

    /* Upload File methods */

    /**
     * Uploads data operations to the server.
     * @async
     * @function
     */
    async uploadData() {
      const operations = this.rows.map((row) => this.createOperation(row));
      const data = { Operations: operations };

      try {
        await ApiService.post("/operation/import", data);
        this.finishProcess("success", vm.$gettext("Operations imported successfully"));
      } catch (error) {
        this.handleUploadError(error);
      }
    },

    /**
     * Handles errors encountered during the upload process.
     * @param {Object} error - Error object from the upload request.
     * @function
     */
    handleUploadError(error) {
      let errorMessage = vm.$gettext("Failed to upload data:");

      if (error.response) {
        if (error.response.status === 500) {
          console.error(error.response.data);
        } else {
          if (error.response.data && Array.isArray(error.response.data.Errors)) {
            error.response.data.Errors.forEach((err) => {
              errorMessage += `\n${err.OperationRef}: ${err.Error}`;
            });
          } else {
            errorMessage += ` ${error.response.data}.`;
          }
          this.finishProcess(ProcessStatus.ERROR, errorMessage);
        }
      } else {
        this.finishProcess(ProcessStatus.ERROR, `${errorMessage} ${error.message}.`);
      }
    },

    /**
     * Creates an operation object from a row of data.
     * @param {Array} row - Array of row data.
     * @returns {Object} - Operation object.
     */
    createOperation(row) {
      let operation = {
        OperationDate: this.formatDate(row[0], "YYYY-MM-DDTHH:mm:ssZ"),
        OperationID: 0,
        OperationType: row[1],
        OperationCMR: row[3],
        OperationRef: row[2],
        MyRole: this.getMyRole(row),
        POVs: [
          {
            Pallets: [],
            Role: this.getMyRole(row),
          },
        ],
        Partners: [],
        Creation: {
          DateTime: this.formatDate(row[0], "YYYY-MM-DDTHH:mm:ssZ"),
          PartnerID: this.getUser.ClientID,
          TimeStamp: new Date().getTime(),
          Name: this.getUser.ClientName,
        },
        Stream: {
          StreamID: "0",
          Operations: [],
        },
      };

      this.addPartnersToOperation(operation, row);
      this.addPalletsToOperation(operation, row);

      return operation;
    },

    /**
     * Adds partners to the operation based on row data.
     * @param {Object} operation - Operation object to update.
     * @param {Array} row - Array of row data.
     * @function
     */
    addPartnersToOperation(operation, row) {
      Object.keys(Roles).forEach((colIndex) => {
        const partnerRef = row[colIndex];
        if (partnerRef) {
          const partner = this.getPartners.find((p) => p.Reference === partnerRef);
          if (partner) {
            operation.Partners.push({
              PartnerID: partner.PartnerID,
              PartnerRole: Roles[colIndex],
              PartnerName: partner.Name,
            });
          }
        }
      });
    },

    /**
     * Adds pallets to the operation based on row data and headers.
     * @param {Object} operation - Operation object to update.
     * @param {Array} row - Array of row data.
     * @function
     */
    addPalletsToOperation(operation, row) {
      for (let i = 10; i < row.length; i += 2) {
        let palletRef = this.headers[i].substring(1);
        if (palletRef) {
          let p = this.getPallets.find((p) => p.PalletRef === palletRef);

          let pallet = {
            PalletID: p.PalletID,
            PalletName: p.PalletName,
            FP: "0",
            FR: "0",
          };

          if (this.headers[i].startsWith("+")) {
            pallet.FP = `${row[i] || 0}`;
          } else if (this.headers[i + 1].startsWith("-")) {
            pallet.FR = `${row[i + 1] || 0}`;
          }

          if (pallet.FP !== "0" || pallet.FR !== "0") {
            operation.POVs[0].Pallets.push(pallet);
          }
        }
      }
    },

    /**
     * Determines the role of the current user based on row data.
     * @param {Array} row - Array of row data.
     * @returns {string} - Role of the current user.
     */
    getMyRole(row) {
      if (row) {
        const myPartnerRef = this.getPartners.find((p) => p.PartnerID === this.getUser.ClientID)?.Reference;
        const roleKeys = Object.keys(Roles);

        for (const colIndex of roleKeys) {
          if (row[colIndex] === myPartnerRef) {
            return Roles[colIndex];
          }
        }
      }
    },

    /**
     * Finalizes the process and displays a message.
     * @param {string} status - Status of the process (e.g., "success", "error").
     * @param {string} message - Message to display.
     * @function
     */
    finishProcess(status, message) {
      this.cancelUpload();
      this.showToast(status, message);
    },

    /* Toast methods */

    /**
     * Displays a toast notification.
     * @param {string} status - Status of the notification (e.g., "success", "error").
     * @param {string} message - Message to display.
     * @function
     */
    showToast(status, message) {
      const Toast = Swal.mixin({
        toast: true,
        icon: status,
        title: message,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      Toast.fire();
    },
  },

  watch: {
    /**
     * Watches for changes in the selected file and triggers file parsing.
     * @param {File} newVal - New file selected.
     */
    selectedFile: {
      handler(newVal) {
        this.parseCSVToTable(newVal);
      },
      immediate: true,
    },

    /**
     * Watches for changes in the number of errors and updates error status.
     * @param {number} newVal - New number of errors.
     */
    numberOfErrors(newVal) {
      this.isActiveError = newVal > 0;
      this.isCorrectFile = newVal === 0;
    },

    /**
     * Watches for changes in the number of warnings and updates warning status.
     * @param {number} newVal - New number of warnings.
     */
    numberOfWarnings(newVal) {
      this.isActiveWarning = newVal > 0;
    },
  },

  mounted() {
    this.isActiveError = this.numberOfErrors > 0;
    this.isActiveWarning = this.numberOfWarnings > 0;
    this.isCorrectFile = this.numberOfErrors === 0;
  },
};
</script>

<style>
.table-container {
  border: solid 1px whitesmoke;
}

.table-custom {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.table-custom thead {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-custom th {
  font-weight: 600;
  color: hsl(13, 2%, 55%);
}

td[contenteditable="true"] {
  outline: none;
}

.error-button,
.warning-button,
.action-button {
  background: none;
  border: 2px solid transparent;
  padding: 5px;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-button:hover,
.warning-button:hover {
  border: 2px solid #ccc;
  background-color: rgba(0, 0, 0, 0.1);
}

.error-button.active {
  border: 2px solid #e74a3b;
}

.warning-button.active {
  border: 2px solid #ffa500;
}

.action-button-upload:hover {
  border: 2px solid #28a745;
  background-color: rgba(40, 167, 69, 0.1);
}

.action-button-refresh:hover {
  border: 2px solid #007bff;
  background-color: rgba(0, 123, 255, 0.1);
}

.action-button-cancel:hover {
  border: 2px solid #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
}

.separator-custom {
  width: 2px;
  height: 100%;
  background-color: #ccc;
}
</style>
